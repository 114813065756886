import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default function createStore() {
	return new Vuex.Store({
		state: {
			host: '',
			// deviceType: 'pc',
			deviceType: 'mobile',
			showDebug: false,
			ads: {
				scriptUrl: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4688525518274277',
				home_insert: [
					{ class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-4688525518274277', 'data-ad-slot': '9170554297', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
					{ class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-4688525518274277', 'data-ad-slot': '7857472625', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
					{ class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-4688525518274277', 'data-ad-slot': '4513123989', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
				],

				detail_1: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-4688525518274277', 'data-ad-slot': '1870648774', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
				detail_masonry: [
					{ class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-4688525518274277', 'data-ad-slot': '9557567105', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
					{ class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-4688525518274277', 'data-ad-slot': '6824699775', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
					{ class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-4688525518274277', 'data-ad-slot': '8244485434', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
					{ class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-4688525518274277', 'data-ad-slot': '8244485434', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
				],
			},
			initialData: null,
			tagData: null,
			adPosConfig: {
				homeinsertCutPos: [
					//0为起始，1为需要左侧展示的图片数量。
					//除了0 后面的4，8 都是根据console算出来的，因为右侧图片会有几个缺失
					[0, 1],
					[4, 3],
					[14, 3],
				], //第二个后和第6个后插入fullsize广告
				home: [2, 6, 14], //从第6开始后的第2个（对应之前的8,11
				detail: [3, 5, 8, 11],
			},

			adsensConfig: {},
			domainConfig: null,
		},
		mutations: {
			setHost(state, host) {
				state.host = host
			},
			setTitle(state, title) {
				state.title = title
			},
			setDomain(state, domainConfig) {
				state.domainConfig = domainConfig
			},
			setDeviceType(state, deviceType) {
				state.deviceType = deviceType
			},
			setDebug(state, showDebug) {
				state.showDebug = showDebug
			},
			setAdsense(state, adsenseConfig) {
				state.adsensConfig = adsenseConfig
			},
			setInitialData(state, initialData) {
				state.initialData = initialData
			},
			setTagData(state, tagData) {
				state.tagData = tagData
			},
		},
		getters: {
			showDebug: (state) => state.showDebug,
			deviceType: (state) => state.deviceType,
			host: (state) => state.host,
			adsensConfig: (state) => state.adsensConfig,
			adPosConfig: (state) => state.adPosConfig,
			domainConfig: (state) => state.domainConfig,
		},
	})
}
